export const routes = [
  {
    path: '/reworks',
    name: 'reworks',
    component: () => import('@/components/rework/Reworks.vue'),
    meta: {
      title: 'Reworks',
      resource: 'Admin',
      action: 'read'
    }
  },
  {
    path: '/rework/:id',
    name: 'rework-details',
    component: () => import('@/components/rework/ReworkDetails.vue'),
    meta: {
      title: 'Rework Details',
      resource: 'Admin',
      action: 'read'
    }
  }
]
