import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    user: null
  },
  getters: {
    GET_USER (state) {
      return state.user
    },
    GET_USER_AVATAR_IMAGE (state) {
      if (!state.user) return null
      return state.user.avatarUrl
    }
  },
  mutations: {
    SET_USER (state, payload) {
      const user = JSON.parse(atob(payload.user))

      if (state.user === null) {
        state.user = {}
      }

      for (let property of Object.keys(user)) {
        Vue.set(state.user, property, user[property])
      }

      localStorage.setItem('USER', payload.user)
    },
    REMOVE_USER (state) {
      state.user = null
      localStorage.removeItem('USER')
    },
    UPDATE_USER_AVATAR (state, payload) {
      Vue.set(state.user, 'avatarUrl', payload.avatarUrl)
      localStorage.setItem('USER', btoa(JSON.stringify(state.user)))
    }
  }
}
