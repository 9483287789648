import { env } from '@/env'
const navigationLinks = [
  {
    title: 'Dashboard',
    icon: 'fas fa-home',
    href: '/dashboard',
    resource: 'Auth',
    action: 'read'
  },
  {
    title: 'Customers',
    icon: 'fas fa-user',
    href: '/customers',
    resource: 'Admin',
    action: 'read'
  },
  {
    title: 'Samples',
    icon: 'fas fa-swatchbook',
    href: '/samples',
    resource: 'Admin',
    action: 'read'
  },
  {
    title: 'Quotes',
    icon: 'fas fa-file-invoice-dollar',
    href: '/quotes',
    resource: 'Admin',
    action: 'read'
  },
  {
    title: 'Orders',
    icon: 'fas fa-receipt',
    href: '/orders',
    resource: 'Admin',
    action: 'read'
  },
  /* {
    title: 'Fulfilment',
    icon: 'fas fa-box-open',
    href: '/fulfilments',
    resource: 'Admin',
    action: 'read'
  }, */
  {
    title: 'Installers',
    icon: 'fas fa-hard-hat',
    href: '/installer',
    resource: 'Admin',
    action: 'read'
  },
  {
    title: 'Installations',
    icon: 'fas fa-tools',
    href: '/installations',
    resource: 'Admin',
    action: 'read'
  }
  /*
  {
    title: 'Carts',
    icon: 'mdi-cart-outline',
    href: '/carts',
    resource: 'Admin',
    action: 'read'
  },
  {
    title: 'Send to TWC',
    icon: 'mdi-inbox',
    resource: 'Admin',
    action: 'read'
  },
  {
    title: 'Sales Users',
    icon: 'mdi-account-box',
    href: '/sales-users',
    resource: 'Admin',
    action: 'read'
  },
  {
    title: 'My Sales',
    icon: 'mdi-home',
    href: '/my-sales',
    resource: 'Admin',
    action: 'read'
  } */
]

if (env.MODE !== 'production') {
  navigationLinks.push({
    title: 'Rework',
    icon: 'fas fa-redo',
    href: '/reworks',
    resource: 'Admin',
    action: 'read'
  })
}

export default navigationLinks
