import { Ability } from '@casl/ability'
import Abilities from './config'

/**
 * Get the abilities of an user based on its role
 * @param {String} role
 */
export const getAbilitiesBasedOnUserRole = role => {
  if (!role) return Abilities.INITIAL
  switch (role.toLowerCase()) {
    case 'admin':
    case 'manager':
    case 'online':
    case 'test':
      return Abilities.ADMIN
    case 'accounts':
      return Abilities.ACCOUNTS
    case 'director':
      return Abilities.DIRECTOR
    case 'digital manager':
      return Abilities.DIGITAL_MANAGER
    case 'installer':
      return Abilities.INSTALLER
    case 'hr':
      return Abilities.HR
    case 'sampler':
      return Abilities.SAMPLER
    case 'sales manager':
      return Abilities.SALES_MANAGER
    case 'operations analyst':
      return Abilities.OPEATIONS_ANALYST
    case 'marketing manager':
      return Abilities.MARKETING_MANAGER
    case 'design consultant':
      return Abilities.DESIGN_CONSULTANT
    case 'samples team leader':
      return Abilities.SAMPLES_TEAM_LEADER
    case 'operations manager':
      return Abilities.OPERATIONS_MANAGER
    case 'sales admin support':
      return Abilities.SALES_ADMIN_SUPPORT
    case 'operations scheduler':
      return Abilities.OPERATIONS_SCHEDULAR
    case 'marketing coordinator':
      return Abilities.MARKETING_COORDINATOR
    case 'operations administrator':
      return Abilities.OPERATIONS_ADMINISTRATOR
    case 'senior design consultant':
      return Abilities.SENIOR_DESIGN_CONSULTANT
    case 'accounts receivable officer':
      return Abilities.ACCOUNTS_RECEIVABLE_OFFICER
    case 'senior design consultant / team leader':
      return Abilities.SENIOR_DESIGN_CONSULTANT_TEAM_LEADER
    case 'accounts manager or finance manager':
      return Abilities.ACCOUNTS_MANAGER_OR_FINANCE_MANAGER
    default:
      return Abilities.INITIAL
  }
}

/**
 * Get the ability for user
 * @param {Object} user
 * @returns {Array}
 */
export const getAbilityForUser = user => {
  if (!user.subRoleID) return Abilities.INITIAL
  const userRole = user.subRoleID.name
  return getAbilitiesBasedOnUserRole(userRole)
}

// initial ability
let ability = Abilities.INITIAL

// If user is logged in check for user role
// Then give user ability
const idToken = localStorage.getItem('id_token')
if (idToken) {
  const user = JSON.parse(atob(localStorage.getItem('user')))
  ability = getAbilityForUser(user)
}

export default new Ability(ability)
