const auth = [
  { action: 'read', subject: 'Auth' }
]

const adminPermission = { action: 'read', subject: 'Admin' }
const processCMStatusPermission = { action: 'process', subject: 'CM status' }
const customCurtainBrandPermissions = [
  { action: 'add', subject: 'Custom Curtain Brand' },
  { action: 'update', subject: 'Custom Curtain Brand' },
  { action: 'delete', subject: 'Custom Curtain Brand' }
]
const customCurtainRangePermissions = [
  { action: 'add', subject: 'Custom Curtain Range' },
  { action: 'update', subject: 'Custom Curtain Range' },
  { action: 'delete', subject: 'Custom Curtain Range' }
]

const admin = [
  { ...adminPermission },
  { ...processCMStatusPermission },
  ...customCurtainBrandPermissions,
  ...customCurtainRangePermissions,
  { action: 'view', subject: 'Item image' },
  { action: 'update', subject: 'Order status' }
]

// initial (guest) not logged in abilities
const INITIAL = [
  { action: 'read', subject: 'Guest' }
]

// Abilities for the admin
const ADMIN = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'My Sales Dashboard' },
  { action: 'read', subject: 'Sales Dashboard' },
  { action: 'read', subject: 'Samples Dashboard' },
  { action: 'read', subject: 'Operations Sales Dashboard' },
  { action: 'read', subject: 'Operations Bookings Dashboard' },
  { action: 'read', subject: 'Dashboard Targets' }
]

// Abilities for the installer
const INSTALLER = [
  ...auth,
  { ...processCMStatusPermission },
  { action: 'read', subject: 'Installer' },
  { action: 'view', subject: 'Item image' },
  { action: 'add', subject: 'Item image' },
  { action: 'update', subject: 'Item image' },
  { action: 'delete', subject: 'Item image' },
  { action: 'update', subject: 'Check Measure Product' }
]

// Design consulatant abilities
const DESIGN_CONSULTANT = [
  ...auth,
  { ...adminPermission },
  { action: 'read', subject: 'My Sales Dashboard' }
]

// Acconts abilities
const ACCOUNTS = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'Sales Dashboard' }
]

// Acconts abilities
const ACCOUNTS_MANAGER_OR_FINANCE_MANAGER = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'Sales Dashboard' },
  { action: 'read', subject: 'Dashboard Targets' }
]

// Acconts receivable officer abilities
const ACCOUNTS_RECEIVABLE_OFFICER = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'Sales Dashboard' }
]

// Digital manager abilities
const DIGITAL_MANAGER = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'My Sales Dashboard' },
  { action: 'read', subject: 'Sales Dashboard' },
  { action: 'read', subject: 'Samples Dashboard' },
  { action: 'read', subject: 'Operations Sales Dashboard' },
  { action: 'read', subject: 'Operations Bookings Dashboard' },
  { action: 'read', subject: 'Dashboard Targets' }
]

// Director abilities
const DIRECTOR = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'My Sales Dashboard' },
  { action: 'read', subject: 'Sales Dashboard' },
  { action: 'read', subject: 'Samples Dashboard' },
  { action: 'read', subject: 'Operations Sales Dashboard' },
  { action: 'read', subject: 'Operations Bookings Dashboard' },
  { action: 'read', subject: 'Dashboard Targets' }
]

// HR abilities
const HR = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'My Sales Dashboard' },
  { action: 'read', subject: 'Sales Dashboard' },
  { action: 'read', subject: 'Samples Dashboard' },
  { action: 'read', subject: 'Operations Sales Dashboard' },
  { action: 'read', subject: 'Operations Bookings Dashboard' }
]

// operations manager abilities
const OPERATIONS_MANAGER = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'My Sales Dashboard' },
  { action: 'read', subject: 'Sales Dashboard' },
  { action: 'read', subject: 'Samples Dashboard' },
  { action: 'read', subject: 'Operations Sales Dashboard' },
  { action: 'read', subject: 'Operations Bookings Dashboard' },
  { action: 'read', subject: 'Dashboard Targets' }
]

// marketing manager abilities
const MARKETING_MANAGER = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'My Sales Dashboard' },
  { action: 'read', subject: 'Sales Dashboard' },
  { action: 'read', subject: 'Samples Dashboard' },
  { action: 'read', subject: 'Operations Sales Dashboard' },
  { action: 'read', subject: 'Operations Bookings Dashboard' }
]

// Operations Analyst abilities
const OPEATIONS_ANALYST = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'My Sales Dashboard' },
  { action: 'read', subject: 'Sales Dashboard' },
  { action: 'read', subject: 'Samples Dashboard' },
  { action: 'read', subject: 'Operations Sales Dashboard' },
  { action: 'read', subject: 'Operations Bookings Dashboard' }
]

// sales manager abilities
const SALES_MANAGER = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'My Sales Dashboard' },
  { action: 'read', subject: 'Sales Dashboard' },
  { action: 'read', subject: 'Samples Dashboard' },
  { action: 'read', subject: 'Operations Sales Dashboard' },
  { action: 'read', subject: 'Operations Bookings Dashboard' },
  { action: 'read', subject: 'Dashboard Targets' }
]

// sales admin support abilities
const SALES_ADMIN_SUPPORT = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'My Sales Dashboard' }
]

// Senior Design Consultant abilities
const SENIOR_DESIGN_CONSULTANT = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'My Sales Dashboard' },
  { action: 'read', subject: 'Sales Dashboard' }
]

// Senior Design Consultant abilities
const SENIOR_DESIGN_CONSULTANT_TEAM_LEADER = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'My Sales Dashboard' },
  { action: 'read', subject: 'Sales Dashboard' },
  { action: 'read', subject: 'Dashboard Targets' }
]

// Sample team leader abilities
const SAMPLES_TEAM_LEADER = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'Samples Dashboard' },
  { action: 'read', subject: 'Dashboard Targets' }
]

// Sampler abilities
const SAMPLER = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'Samples Dashboard' }
]

// Marketing coordinator abilities
const MARKETING_COORDINATOR = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'Sales Dashboard' }
]

// Operations Administrator abilities
const OPERATIONS_ADMINISTRATOR = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'Operations Sales Dashboard' }
]

// Operations Scheduler abilities
const OPERATIONS_SCHEDULAR = [
  ...auth,
  ...admin,
  { action: 'read', subject: 'Operations Bookings Dashboard' }
]

const Abilities = {
  INITIAL,
  ADMIN,
  INSTALLER,
  ACCOUNTS,
  DIRECTOR,
  HR,
  SAMPLER,
  SALES_MANAGER,
  DIGITAL_MANAGER,
  OPEATIONS_ANALYST,
  MARKETING_MANAGER,
  DESIGN_CONSULTANT,
  SAMPLES_TEAM_LEADER,
  OPERATIONS_MANAGER,
  SALES_ADMIN_SUPPORT,
  OPERATIONS_SCHEDULAR,
  MARKETING_COORDINATOR,
  OPERATIONS_ADMINISTRATOR,
  SENIOR_DESIGN_CONSULTANT,
  ACCOUNTS_RECEIVABLE_OFFICER,
  SENIOR_DESIGN_CONSULTANT_TEAM_LEADER,
  ACCOUNTS_MANAGER_OR_FINANCE_MANAGER
}

export default Abilities
