<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant.sync="MINI_STATE"
    :mini-variant-width="sidebarMinWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :class="{ 'drawer-mini': !DRAWER_STATE }"
  >
    <!-- <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{'drawer-mini': !DRAWER_STATE}"> -->

    <v-list nav dense>
      <v-list-item
        link
        @click.stop="
          DRAWER_STATE = !DRAWER_STATE
          MINI_STATE = !MINI_STATE
        "
      >
        <v-list-item-icon>
          <v-icon v-if="!DRAWER_STATE || !$vuetify.breakpoint.mdAndUp"
            >mdi-arrow-left</v-icon
          >
          <v-icon v-else>mdi-menu</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <template v-for="item in navLinks">
        <v-list-item
          v-if="canViewLink(item)"
          link
          :key="item.title"
          :to="item.href"
          @click.stop="MINI_STATE = true"
        >
          <!-- <v-list-item-icon>
            <v-img src="../../static/Quotes-Blue.svg"></v-img>
          </v-list-item-icon> -->
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import navLinks from '@/navigation'
import { canViewLink } from '@/libs/acl/utils'

export default {
  props: {
    source: String
  },
  data () {
    return {
      navLinks,
      canViewLink,
      // drawer: true,
      // mini: true,
      items: [],
      sidebarWidth: 240,
      sidebarMinWidth: 67
    }
  },
  // watch: {
  //   mini () {
  //     console.log('Mini')
  //     console.log(this.mini)
  //   }
  // },
  computed: {
    ...mapState(['drawer']),
    DRAWER_STATE: {
      get () {
        return this.drawer
      },
      set (newValue) {
        if (newValue === this.drawer) return
        this.TOGGLE_DRAWER()
      }
    },
    ...mapState(['mini']),
    MINI_STATE: {
      get () {
        return this.mini
      },
      set (newValue) {
        if (newValue === this.mini) return
        this.TOGGLE_MINI(newValue)
      }
    }
  },
  methods: {
    ...mapActions([ 'TOGGLE_DRAWER' ]),
    ...mapActions([ 'TOGGLE_MINI' ])
  }
}
</script>
<style>
.v-navigation-drawer {
  top: 0px !important;
  height: auto !important;
}

/* .theme--light.v-navigation-drawer{
  background-color: #F8F8F8;
} */
.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: #1A4314;
}
.mdi-square {
  font-size: 20px !important;
}
</style>

<style scoped>
.v-divider {
  border: none !important;
}
.v-navigation-drawer--fixed {
  position: absolute !important;
}
.v-navigation-drawer {
  background: #1A1A1A !important;
  max-height: 100% !important;
  height: 100% !important;
}
.theme--light.v-icon,
.v-list-item__title {
  color: #737c90 !important;
}
.v-list-item {
  padding: 6px 10px !important;
}
.v-list-item--active .theme--light.v-icon,
.v-list-item--active .v-list-item__title {
  color: #ffffff !important;
}
.v-list-item--active {
  background: #1A4314 !important;
}
.v-list--nav {
  padding: 0 !important;
}
</style>
