import ability, { getAbilityForUser } from './ability'
import Abilities from './config'

/**
 * Check if user can view item based on it's ability
 * Based on item's action and resource
 * @param {Object} item navigation object item
 */
export const canViewLink = item => ability.can(item.action, item.resource)

/**
 * Update the existing abilities
 * @param {Array} abilities
 */
export const updateAbility = abilities => ability.update(abilities)

/**
 * Update the existing abilities
 * @param {Object} user
 */
export const updateUserAbilities = user => {
  const abilities = getAbilityForUser(user)
  return updateAbility(abilities)
}

/**
 * Reset user ability to initial ability
 */
export const resetUserAbilities = () => updateAbility(Abilities.INITIAL)
