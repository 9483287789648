export const env = {
  INSTALL: false,
  EX_GST: true,
  MOTORIZATION: true,
  NODE_ENV: process.env.NODE_ENV,
  API_URL: process.env.VUE_APP_API_URL,
  MEDIA_URL: process.env.VUE_APP_MEDIA_URL,
  S3_BUCKET: process.env.VUE_APP_S3_BUCKET,
  MODE: process.env.VUE_APP_MODE,
  LOGTAIL_SOURCE_TOKEN: process.env.VUE_APP_LOGTAIL_SOURCE_TOKEN
}
