<template>
  <v-app-bar app>
    <v-app-bar-nav-icon
      v-if="!$vuetify.breakpoint.mdAndUp"
      @click="
        DRAWER_STATE = !DRAWER_STATE;
        MINI_STATE = !MINI_STATE;
      "
    />
    <v-spacer v-if="!$vuetify.breakpoint.mdAndUp" />
    <router-link to="/dashboard/sales">
      <v-img
        max-width="98"
        max-height="24"
        :src="mediaUrl + '/adminTool/Home_Pro_Logo_Black.png'"
        :class="$vuetify.breakpoint.mdAndUp ? 'logo' : ''"
      />
    </router-link>
    <v-spacer />

    <v-banner single-line v-bind="attrs" v-on="on">
      <v-avatar v-if="loggedIn" color="orange" size="30" v-bind="attrs" v-on="on">
        <v-img v-if="userAvatarImageUrl" :src="userAvatarImageUrl" alt="user avatar" />
        <span v-else class="white--text headline">{{ loggedInUser.firstName.charAt(0) }}</span>
      </v-avatar>
      <div
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin: auto; margin-left: 5px'
            : 'margin: auto; margin-left: 5px'
        "
      >
        <span
          class="grey--text"
          style="font-weight: bold; font-size: 14px;"
          v-if="$vuetify.breakpoint.mdAndUp && loggedIn"
        >
          {{ loggedInUser.firstName }}
        </span>
        <v-menu offset-y bottom style="padding: 27px">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" size="24" style="margin: 0 5px">
              mdi-chevron-down
            </v-icon>
          </template>

          <v-list>
            <v-list-item v-for="(item, i) in notifications" :key="i">
              <v-list-item-title class="menu_items" @click="actions(item)">{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-banner>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import config from '../../config'
import { resetUserAbilities } from '@/libs/acl/utils'
import { logger } from '@/utils/logtail';

export default {
  name: 'Header',
  data: () => ({
    config,
    mediaUrl: localStorage.getItem('mediaUrl'),
    attrs: null,
    on: null,
    notifications: [
      { text: 'Account Settings' },
      { text: 'Admin' },
      // { text: 'External Supplier Conditions' },
      { text: 'Logout' }
    ],
    account: [
      { text: 'Profile', icon: 'mdi-account', color: 'textColor' },
      { text: 'Tasks', icon: 'mdi-thumb-up', color: 'textColor' },
      { text: 'Messages', icon: 'mdi-flag', color: 'textColor' }
    ],
    notificationsBadge: true,
    messageBadge: true,
    loggedIn: localStorage.getItem('id_token'),
    loggedInUser: localStorage.getItem('user')
  }),
  created () {
    if (this.loggedIn && this.loggedInUser) {
      this.loggedInUser = JSON.parse(atob(this.loggedInUser))
      this.$store.commit('user/SET_USER', { user: localStorage.getItem('user') })
    }
  },
  computed: {
    ...mapState(['drawer']),
    ...mapState(['mini']),
    DRAWER_STATE: {
      get () {
        return this.drawer
      },
      set (newValue) {
        if (newValue === this.drawer) return
        this.TOGGLE_DRAWER()
      }
    },
    MINI_STATE: {
      get () {
        return this.mini
      },
      set (newValue) {
        if (newValue === this.mini) return
        this.TOGGLE_MINI(false)
      }
    },
    userAvatarImageUrl () {
      if (
        !this.$store.getters['user/GET_USER'] &&
        !this.$store.getters['user/GET_USER_AVATAR_IMAGE']
      ) return null
      return this.$store.getters['user/GET_USER'].avatarUrl
    }
  },
  methods: {
    ...mapActions(['TOGGLE_DRAWER']),
    ...mapActions([ 'TOGGLE_MINI' ]),
    logout () {
      window.localStorage.setItem('authenticated', false)
      localStorage.removeItem('id_token')
      localStorage.removeItem('is_admin')
      localStorage.removeItem('user')
      this.$store.commit('user/REMOVE_USER')
      resetUserAbilities() // reset user abilities
      logger.info(`LOGOUT - ${this.loggedInUser.firstName} ${this.loggedInUser?.lastName}`, {
        category: 'logout',
        status: 'success',
        userId: this.loggedInUser._id,
        email: this.loggedInUser?.email,
        firstName: this.loggedInUser.firstName,
        lastName: this.loggedInUser?.lastName
      })
      this.$router.push({ name: 'Login', params: {error: 'logout'} })
    },
    actions (value) {
      if (value.text === 'Logout') {
        this.logout()
      }
      if (value.text === 'Admin') {
        this.$router.push({ name: 'admin' })
      }
      if (value.text === 'Account Settings') {
        this.$router.push({ name: 'account-settings' })
      }
      // if (value.text === 'External Supplier Conditions') {
      //   this.$router.push({ name: 'ExternalSupplierConditions' })
      // }
    }
  }
}
</script>

<style>
.theme--light.v-banner.v-sheet:not(.v-sheet--outlined):not(.v-sheet--shaped)
  .v-banner__wrapper {
  border-bottom: none !important;
  margin-right: 10px !important;
}
/* .v-application--is-ltr .v-banner__actions{
  margin-left:0px
} */
.v-banner__text {
  display: inline-flex !important;
}
/* .v-toolbar__content {
  border-bottom: 0.1px solid #d6d6d6;
} */
/* .main-header {
  box-shadow: none !important;
  background-color: #ffffff !important;
} */
/* .v-banner{
  border-left: 0.1px solid #D6D6D6 !important;
} */
/* .v-application .primary {
  background-color: #ffffff !important;
} */

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: white !important;
}

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.v-app-bar.v-app-bar--fixed {
  position: absolute !important;
}
.logo {
  position: absolute;
  top: 17px;
  left: 29px;
}
.v-list .v-sheet .theme--light {
  padding: 27px !important;
}
.menu_items {
  cursor: pointer;
}

</style>
