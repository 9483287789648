<template>
  <div id="app">
    <v-app>
      <template v-if="isNotFullLayout">

        <Sidebar  />
        <Header />
      </template>
      <v-main class="content">
        <!-- Added key in routerview to refresh page when same route name but diff params -->
        <router-view :key="$route.fullPath" />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Header from '@/components/layout/header'
import Sidebar from '@/components/layout/sidebar'
import { env } from '@/env'

// import "./styles/app.scss";
export default {
  name: 'App',
  data () {
    return {
      // drawer: false,
      loggedInUser: localStorage.getItem('user'),
      loggedIn: localStorage.getItem('id_token')
    }
  },
  components: {Header, Sidebar},
  computed: {
    isNotFullLayout () {
      return this.$route.meta?.layout !== 'full'
    }
  },
  created () {
    try {
      const mediaUrl = localStorage.getItem('mediaUrl')

      if (!mediaUrl || mediaUrl !== env.MEDIA_URL) {
        localStorage.setItem('mediaUrl', env.MEDIA_URL)
      }
    } catch (e) {
      console.log(e)
    }
    if (this.loggedIn) {
      this.loggedInUser = JSON.parse(atob(this.loggedInUser))
    }
    // run this function on every page reload
    // hiding in production
    // if (window.location.href.indexOf('localhost') > 0 || window.location.href.indexOf('stagingadmin') > 0) {
    //   this.notProduction = true
    // }
  },
  methods: {
    logout () {
      localStorage.removeItem('id_token')
      localStorage.removeItem('is_admin')
      localStorage.removeItem('user')
      this.$router.push({ name: 'Login', params: { error: 'logout' } })
    }
  }
}
</script>

<style>
/* .v-main__wrap{
  padding: 20px 24px 24px;
} */

.theme--light.v-application {
  background-color: #f4f4f4 !important;
}
.gray {
  background-color: #1a1a1a !important;
}

.body,
.v-application {
  font-family: 'Libre Franklin', sans-serif !important;
  letter-spacing: 0 !important;
  color: #1a1a1a !important;
}
h1 {
  /* height: 50px; */
  font-family: 'Libre Franklin Medium', sans-serif !important;
  /* width: 623px; */
  font-size: 40px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 50px !important;
}

/* h2,h3,h4,h5,h6{
  font-family: 'Libre Franklin Bold', sans-serif;
} */

h2,
h3,
h4,
h5,
h6 {
  height: 50px !important;
  /* width: 401px; */
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 50px !important;
}

.v-app-bar__nav-icon {
  background-color: #323c53 !important;
  color: #737c90 !important;
  border-radius: 0% !important;
  margin-left: -16px !important;
  margin-top: -8px !important;
}

.theme--light.v-application {
  color: #1a1a1a !important;
}

.theme--light.v-card {
  color: #1a1a1a !important;
}

/* b{
   font-family: 'Libre Franklin Bold', sans-serif;
} */
.font-16 {
  font-size: 16px !important;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}

.bg-white {
  background-color: white !important;
}
.header-custom {
  background-color: white !important;
  padding-left: 39px !important;
  padding-right: 39px !important;
}

@media only screen and (max-width: 768px) {
  .header-custom {
    background-color: white;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.inner-content {
  padding: 23px 26px;
  margin-bottom: 32px !important;
}

.spacing {
  padding: 45px 39px !important;
}

.v-list-item {
  min-height: 32px !important;
}

.v-btn {
  text-transform: initial !important;
}

thead {
  background-color: #f4f5f8 !important;
}

.v-text-field--outlined fieldset {
  top: 0px !important;
}

.theme--light.v-data-table tbody tr.v-data-table__selected {
  background-color: inherit !important;
}

.theme-bg {
  background-color: #323c53 !important;
}

/* For Table */
.negative-margin {
  margin-left: -37px !important;
  margin-right: -37px !important;
}

.v-data-table {
  border: 0px !important;
}

.theme--light.v-data-table {
  color: #1a1a1a !important;
}

td {
  height: 66px !important;
}

.v-date-picker-table td {
  height: unset !important;
}

/* Table Hover */
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fbfcfe !important;
}

.v-slide-group__wrapper {
  padding-left: 39px !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon {
  color: #1a1a1a !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab.v-tab--active,
.theme--light.v-tabs > .v-tabs-bar .v-tab.v-tab--active > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab.v-tab--active > .v-icon{
  color: #1A4314 !important;
}

.v-tabs-slider{
  color: #1A4314 !important;
}

.theme--light.v-card .v-card__subtitle,
.theme--light.v-card > .v-card__text {
  color: #1a1a1a !important;
}

.v-tab {
  letter-spacing: 0px !important;
}

.v-card__subtitle {
  font-size: 12px !important;
}

/* For all labels */
label {
  font-size: 12px;
  font-weight: bold;
  line-height: 30px;
}

/* .v-select__slot > label{
  font-weight: normal;
} */

.v-label.theme--light {
  font-weight: normal !important;
}

/* For all buttons */
.v-btn {
  letter-spacing: 0px !important;
  /* opacity: 0.8; */
}

.v-input__slot > fieldset {
  border: 2px solid #e3e5ed !important;
  /* background-color: rgba(248,249,253,0.5); */
}

.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: rgba(248, 249, 253, 0.5) !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #1a1a1a !important;
}

.row + .row {
  margin-top: 0px !important;
}

/* .v-text-field__details {
  display: none;
} */

.v-label span {
  height: 50px !important;
  width: 530px !important;
  opacity: 0.3 !important;
  color: #1a1a1a !important;
  font-family: 'Libre Franklin' !important;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
}

/* For Dialog Box backdrop */
.v-overlay--active > .v-overlay__scrim {
  opacity: 0.999 !important;
  background-color: rgba(50, 60, 83, 0.95) !important;
}

.v-dialog--active > .v-card > .v-card__title {
  padding-top: 26px !important;
}

/* For Action List Cursor should be pointer */
.v-list-item {
  cursor: pointer !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  background-color: white;
}
.primary-color {
  color: #1A4314;
}

.v-dialog > .v-card > .v-card__text.dialog__card-text {
  padding: 0 16px 0.3rem !important;
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.3;
  letter-spacing: 0 !important;
}
.v-card__actions > .v-btn.dialog__card-btn {
  padding: 1.3rem 1.2rem !important;
}
</style>
